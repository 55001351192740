<template>
  <div class=" ">
    <!-- <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">匯入名單</h2>
    </div> -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y  px-10 mt-10">
      <div v-show="step === 1">
        <div class="font-bold text-xl mb-5">匯入名單</div>

        <div class=" border-b border-gray-600 mb-5">
          <div class=" text-gray-600 text-base mb-3">
            請於下方逐行輸入欲新增成員的業務信箱，一行輸入一筆，<br />
            舉例如下：
          </div>
          <div class="  text-base mb-3">
            abbb123@ccc.com <br />
            eeb234@ccc.com <br />
            ff456wwww@ccc.com <br />
          </div>
          <div class=" text-gray-600 text-base mb-3">
            以此類推，至多100筆
          </div>
        </div>
        <div class=" mb-8">
          <textarea
            name=""
            id=""
            :rows="10"
            v-model="emails"
            class="intro-x input input--lg border border-gray-300 w-full"
            placeholder="輸入Email"
          ></textarea>
        </div>
        <div class=" flex items-center justify-end">
          <a
            href="javascript:void(0)"
            @click="router.push('/member')"
            class="button button--lg text-theme-1 bg-transparent mr-2"
          >
            返回
          </a>
          <a
            href="javascript:void(0)"
            class="button button--lg text-white bg-theme-1 shadow-md mr-2"
            @click="nextStep"
          >
            下一步
          </a>
        </div>
      </div>
      <div v-show="step === 2">
        <div
          class="intro-y flex flex-col sm:flex-row items-center justify-between mt-8 mb-5"
        >
          <div class="font-bold text-xl">本次匯入名單</div>
          <div class=" flex items-center">
            <div class=" flex items-center text-lg mr-2">
              <button
                type="button"
                class=" text-blue-600 text-xs flex items-center"
                @click="modal.invitation = true"
              >
                <AlertCircleIcon
                  class="mr-1 text-theme-10 h-6 w-6"
                />查看邀請碼範本
              </button>
            </div>
            <div class=" flex items-center text-lg mr-2">
              <button
                type="button"
                class=" text-blue-600 text-xs flex items-center"
                @click="modal.notification = true"
              >
                <AlertCircleIcon
                  class="mr-1 text-theme-10 h-6 w-6"
                />查看轉換通知信範本
              </button>
            </div>
          </div>
        </div>

        <div class=" box p-5 mb-5">
          <div class="overflow-x-auto scrollbar-hidden mt-2 ">
            <!-- <grid
              ref="grid"
              v-bind="gridOptions"
              @removeSelectedRows="hideBatchDropDown()"
            >
            </grid> -->
            <vxe-table
              ref="table"
              stripe
              border
              round
              :data="collections"
              :edit-rules="validRule"
            >
              <vxe-table-column
                field="contact_Email"
                title="業務信箱"
              ></vxe-table-column>
              <vxe-table-column
                field="status"
                title="帳號狀態"
              ></vxe-table-column>
              <vxe-table-column field="contact_Title" title="職稱">
                <template #default="{row}">
                  <template v-if="row.isEditable">
                    <vxe-input
                      v-model="row.contact_Title"
                      placeholder="輸入職稱"
                    ></vxe-input>
                  </template>
                  <template v-else>
                    {{ row.contact_Title }}
                  </template>
                </template>
              </vxe-table-column>
              <vxe-table-column field="position" title="權限" :resizable="true">
                <template #default="{row}">
                  <template v-if="row.isEditable">
                    <vxe-select
                      transfer
                      v-model="row.position"
                      :options="positionList"
                      placeholder="選擇權限"
                    ></vxe-select
                  ></template>
                  <template v-else>
                    {{ getPosition(row.position) }}
                  </template>
                </template>
              </vxe-table-column>
              <vxe-table-column
                width="300"
                field="remark"
                title="操作與備註"
                :resizable="true"
              ></vxe-table-column>
              <vxe-table-column width="50" title="選擇">
                <template #default="{row}">
                  <template v-if="row.isSelectable">
                    <vxe-checkbox
                      v-model="row.isSelected"
                      type="che"
                      placeholder="輸入職稱"
                    ></vxe-checkbox>
                  </template>
                  <template v-else></template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </div>
        </div>

        <div class=" flex items-center justify-end">
          <a
            href="javascript:void(0)"
            class="button button--lg text-theme-1 bg-transparent mr-2"
            @click="() => (step = 1)"
          >
            上一步
          </a>

          <a
            href="#"
            class="button button--lg text-white bg-theme-1 shadow-md mr-2 flex items-center"
            @click="submit"
            v-if="disabedButton === false"
          >
            <CheckIcon class="mr-2 h-4 w-4" />確認執行
          </a>

          <a
            href="#"
            class="button button--lg w-full xl:w-32 text-white bg-theme-13 flex items-center disabled"
            v-if="disabedButton === true"
          >
            <CheckIcon class="mr-2 h-4 w-4" />確認執行
          </a>
        </div>
      </div>
    </div>

    <vxe-modal transfer v-model="modal.invitation" width="600" show-footer>
      <template #title>
        <span>邀請碼範本</span>
      </template>
      <template #default>
        <!-- <div class="text-xl font-bold p-1">邀請碼信件：</div> -->
        <div class="text-base p-1">
          主旨：數位服務媒合平台【組織成員邀請】-[公司名稱]
        </div>
        <div>&nbsp;</div>
        <div class="text-base p-1">
          您在[發信時間xxxx-xx-xx]由[發信者名稱]邀請加入[發信者公司名稱]
        </div>
        <div class="text-base p-1">
          請於[發信時間T+10D xxxx-xx-xx]前依指示以加入企業
        </div>
        <div class="text-base p-1">這是您的邀請碼：[邀請碼]</div>
        <div>&nbsp;</div>
        <div class="text-base p-1">1.登入數位服務媒合平台[文字攜連結]</div>
        <div class="text-base p-1">2.註冊或升級為【買家帳號】</div>
        <div class="text-base p-1">
          3.進入【供應商企業資料>加入供應商】輸入邀請碼，並送出
        </div>
        <div class="text-base p-1">4.開始使用供應商身分專屬服務</div>
        <div>&nbsp;</div>
        <div class="text-base p-1">謝謝</div>
        <div class="text-base p-1">數位服務媒合平台營運小組</div>
      </template>
    </vxe-modal>

    <vxe-modal transfer v-model="modal.notification" width="800" show-footer>
      <template #title>
        <span>轉換通知信範本</span>
      </template>
      <template #default>
        <!-- <div class="text-xl font-bold p-1">轉換通知信範本：</div> -->
        <div class="text-base p-1">
          主旨：數位服務媒合平台[供應商名稱]職稱更新通知
        </div>
        <div>&nbsp;</div>
        <div class="text-base p-1">
          [使用者名稱]，您在[職稱修改時間xxxx-xx-xx]，在[公司名稱]職稱更新修改為[職稱]
        </div>
        <div class="text-base p-1">
          感謝您一直以來對於我們團隊的貢獻，讓我們可以共同打造更好的工作環境。
        </div>
        <div class="text-base p-1">
          此為系統通知信件，如果您對於這項更新有任何疑問或需要進一步的協助，請聯繫[供應商名稱]。
        </div>

        <div>&nbsp;</div>
        <div class="text-base p-1">謝謝</div>
        <div class="text-base p-1">數位服務媒合平台營運小組</div>
      </template>
    </vxe-modal>

    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import { valHooks } from "jquery";
import { useRouter } from "vue-router";
import { required } from "@vuelidate/validators";
import { toRef } from "vue-demi";
import { VXETable } from "vxe-table";
export default defineComponent({
  components: {
    Grid,
    CheckBoxList
  },
  setup() {
    const step = ref(1);
    const model = CloudFun.current?.model;
    const grid = ref({} as any);
    const emails = ref();
    const collections = ref<any[]>();
    const positionList = ref([] as any[]);
    const router = useRouter();
    const disabedButton = ref(false);
    const table = ref();
    const modal = reactive({
      notification: false,
      invitation: false
    });
    const getPosition = (value: any) => {
      const item = positionList.value.find(el => el.value === value);
      if (item) return item.label;
      return "";
    };

    const validRule = ref({
      contact_Title: [{ required: true, message: "請填寫職務" }],
      position: [{ required: true, message: "請選取權限" }]
    });

    const nextStep = () => {
      model?.dispatch("vendor/memberBatch", { emails: emails.value }).then(
        payload => {
          const json = JSON.stringify(payload.data, (key, value) => {
            return key === "position" && value === 0 ? null : value;
          });

          collections.value = payload.data.map((item: any) => {
            return {
              contact_Email: item.contact_Email,
              status: item.status,
              isSelectable: item.isSelectable,
              isEditable: item.isEditable,
              contact_Title: item.contact_Title,
              position: item.position === 0 ? null : item.position,
              remark: item.remark,
              isSelected: false
            };
          });

          const count = collections.value?.filter(el => {
            return el.isSelectable === true;
          });
          disabedButton.value = count ? count.length === 0 : false;
          step.value = 2;
        },
        reason => {
          console.log(
            "🚀 ~ file: ImportList.vue ~ line 266 ~ nextStep ~ reason",
            reason
          );
        }
      );
    };

    onMounted(async () => {
      const positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);

      if (positionData) {
        positionList.value = Object.values(positionData.Vendor_Position).map(
          (item: any) => {
            return { label: item.text, value: parseInt(item.value) };
          }
        );
      }
    });

    return {
      step,
      grid,
      emails,
      collections,
      nextStep,
      positionList,
      getPosition,
      router,
      validRule,
      table,
      disabedButton,
      modal
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async validate() {
      const selectedList = this.collections?.filter(el => el.isSelected);

      const editableList = this.collections?.filter(
        el => el.isSelected && el.isEditable
      );

      const errorMessage = Array<string>();

      if (selectedList?.length === 0) {
        VXETable.modal.alert({
          content: `請選取要匯入項目`,
          title: `選取驗證`,
          status: "error"
        });
        return false;
      }

      if (editableList && editableList.length > 0) {
        editableList?.forEach(item => {
          if (item.contact_Title.length === 0) {
            errorMessage.push(`${item.contact_Email}　請輸入職稱\n`);
          }

          if (item.position === 0 || item.position === null) {
            errorMessage.push(`${item.contact_Email}　請選擇權限\n`);
          }
        });
      }

      if (errorMessage.length > 0) {
        VXETable.modal.alert({
          content: `${errorMessage.join("")}`,
          title: `資料驗證`,
          status: "error"
        });
      }

      return errorMessage.length === 0;
    },
    async submit() {
      const validate = await this.validate();

      if (validate) {
        const applyList = this.collections?.filter(el => el.isSelected);

        const data = applyList?.map(item => {
          return {
            contact_Email: item.contact_Email,
            contact_Title: item.contact_Title,
            position: item.position ? item.position : 0
          };
        });

        this.$model
          .dispatch("vendor/memberBatchApply", {
            members: data
          })
          .then(
            payload => {
              console.log(
                "🚀 ~ file: ImportList.vue ~ line 285 ~ submit ~ payload",
                payload
              );

              if (payload.status) {
                alert("執行完成");
                this.$router.replace("/member");
              }
            },
            reason => {
              console.log(
                "🚀 ~ file: ImportList.vue ~ line 291 ~ submit ~ reason",
                reason
              );
            }
          );
      }
    }
  }
});
</script>
