
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import { valHooks } from "jquery";
import { useRouter } from "vue-router";
import { required } from "@vuelidate/validators";
import { toRef } from "vue-demi";
import { VXETable } from "vxe-table";
export default defineComponent({
  components: {
    Grid,
    CheckBoxList
  },
  setup() {
    const step = ref(1);
    const model = CloudFun.current?.model;
    const grid = ref({} as any);
    const emails = ref();
    const collections = ref<any[]>();
    const positionList = ref([] as any[]);
    const router = useRouter();
    const disabedButton = ref(false);
    const table = ref();
    const modal = reactive({
      notification: false,
      invitation: false
    });
    const getPosition = (value: any) => {
      const item = positionList.value.find(el => el.value === value);
      if (item) return item.label;
      return "";
    };

    const validRule = ref({
      contact_Title: [{ required: true, message: "請填寫職務" }],
      position: [{ required: true, message: "請選取權限" }]
    });

    const nextStep = () => {
      model?.dispatch("vendor/memberBatch", { emails: emails.value }).then(
        payload => {
          const json = JSON.stringify(payload.data, (key, value) => {
            return key === "position" && value === 0 ? null : value;
          });

          collections.value = payload.data.map((item: any) => {
            return {
              contact_Email: item.contact_Email,
              status: item.status,
              isSelectable: item.isSelectable,
              isEditable: item.isEditable,
              contact_Title: item.contact_Title,
              position: item.position === 0 ? null : item.position,
              remark: item.remark,
              isSelected: false
            };
          });

          const count = collections.value?.filter(el => {
            return el.isSelectable === true;
          });
          disabedButton.value = count ? count.length === 0 : false;
          step.value = 2;
        },
        reason => {
          console.log(
            "🚀 ~ file: ImportList.vue ~ line 266 ~ nextStep ~ reason",
            reason
          );
        }
      );
    };

    onMounted(async () => {
      const positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);

      if (positionData) {
        positionList.value = Object.values(positionData.Vendor_Position).map(
          (item: any) => {
            return { label: item.text, value: parseInt(item.value) };
          }
        );
      }
    });

    return {
      step,
      grid,
      emails,
      collections,
      nextStep,
      positionList,
      getPosition,
      router,
      validRule,
      table,
      disabedButton,
      modal
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async validate() {
      const selectedList = this.collections?.filter(el => el.isSelected);

      const editableList = this.collections?.filter(
        el => el.isSelected && el.isEditable
      );

      const errorMessage = Array<string>();

      if (selectedList?.length === 0) {
        VXETable.modal.alert({
          content: `請選取要匯入項目`,
          title: `選取驗證`,
          status: "error"
        });
        return false;
      }

      if (editableList && editableList.length > 0) {
        editableList?.forEach(item => {
          if (item.contact_Title.length === 0) {
            errorMessage.push(`${item.contact_Email}　請輸入職稱\n`);
          }

          if (item.position === 0 || item.position === null) {
            errorMessage.push(`${item.contact_Email}　請選擇權限\n`);
          }
        });
      }

      if (errorMessage.length > 0) {
        VXETable.modal.alert({
          content: `${errorMessage.join("")}`,
          title: `資料驗證`,
          status: "error"
        });
      }

      return errorMessage.length === 0;
    },
    async submit() {
      const validate = await this.validate();

      if (validate) {
        const applyList = this.collections?.filter(el => el.isSelected);

        const data = applyList?.map(item => {
          return {
            contact_Email: item.contact_Email,
            contact_Title: item.contact_Title,
            position: item.position ? item.position : 0
          };
        });

        this.$model
          .dispatch("vendor/memberBatchApply", {
            members: data
          })
          .then(
            payload => {
              console.log(
                "🚀 ~ file: ImportList.vue ~ line 285 ~ submit ~ payload",
                payload
              );

              if (payload.status) {
                alert("執行完成");
                this.$router.replace("/member");
              }
            },
            reason => {
              console.log(
                "🚀 ~ file: ImportList.vue ~ line 291 ~ submit ~ reason",
                reason
              );
            }
          );
      }
    }
  }
});
